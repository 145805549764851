.site-menu
{
    font-size: calc(10px + 2vmin);
    /*background-color: #282c34;*/
    color: #fff;
    padding: 0 5%;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    position:fixed;
    z-index:2;
}

#site-title
{
    float:left;
    margin-block-start:1em;
    margin-block-end:1em;
}

.navigation
{
    float:right;
}

.navigation ul li
{
    display:inline-block;
    margin:0 5px;
    font-size: calc(10px + 1vmin);
    padding:10px 25px;
    background-size:200% 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #9134fc 50%);
    transition: background-position 0.5s;
    border-radius:10px;
    cursor:pointer;
}

.navigation ul li:hover 
{
    background-position: -100% 0;
}

.hamburger-toggle
{
    display:none;
}

.hamburger-toggle div
{
    width:25px;
    height:3px;
    background-color:#fff;
    margin:6px 0;
}

#contact-btn::after
{
    content: "";
    display:block;
    width:100%;
    height:5px;
    background-color:#9134fc;
}

@media screen and (max-width:769px)
{
    .navigation
    {
        display:none;
    }

    .hamburger-toggle
    {
        display:block;
        margin-block-start:1em;
        margin-block-end:1em;
    }

    .navigation.active
    {
        flex-basis:100%;
        display:block;
        text-align:center;
    }

    .navigation.active ul li
    {
        display:block;
        padding:0.5em;
    }

    #contact-btn::after
    {
        height:0;
    }
}

.btn-open
{
    background-size:200% 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #9134fc 50%);
    transition: background-position 0.5s;
    background-position: -100% 0;
}