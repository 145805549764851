#home-greeting
{
    background-image: url('../media/lake-me.jpg');
    background-color: rgb(106, 115, 218);
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    cursor:default;
    height:calc(100vh);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
}

#home-greeting::before
{
    opacity: 0.6;
    background: #000000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    display: inline-block;
    content: " ";
}

#home-greeting div
{
    z-index:2;
}

#home-greeting .heading-container p
{
    color: #b5b5b5;
    font-size: 20px;
    margin: 0;
    font-weight: 300;
}

#home-greeting a
{
    text-decoration:underline;
}