.slide-form-container
{
    position:absolute;
    left:0;
    right:0;
    top:calc(50% - (204px + 8%));
    margin:auto;
    width:60%;
    background-color:#e9e9e9;
    z-index:2;
    padding:8% 8%;
}

.slide-form-container::before
{
    content:'';
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:#ffffffb8;
    z-index:-1;
}

.slide-form-container h3
{
    color:#9134fc;
    font-size:2em;
    text-transform:uppercase;
    font-weight:900;
    margin-bottom:0;
}

.slide-form-container p
{
    margin-top:0;
    color:#9e9e9e;
}

.slide-form-container .close-me
{
    position: absolute;
    top: -16px;
    right: -8px;
    font-size: 32px;
    font-weight: 900;
    cursor: pointer;
}

.slide-form-container .form-fields-wrapper
{
    display:flex;
    padding:10px 0;
}

.slide-form-container .input-container
{
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    padding:0 25px 0 0;
    flex-basis:50%;
}

.slide-form-container .input-container input
{
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    margin: 0 0 10px 0;
    padding: 10px;
    text-transform: uppercase;
}

.slide-form-container .input-container input:focus-visible, .slide-form-container .input-container textarea:focus-visible
{
    border: 2px solid #9134fc;
    outline: none;
}

.slide-form-container textarea
{
    height:calc(100% - 10px);
    padding:10px;
    border: 1px solid #c1c1c1;
    resize:none;
    border-radius:5px;
}

.slide-form-container input[type="submit"]
{
    padding: 20px 80px;
    border: none;
    background-color: #9134fc;
    color: #fff;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: 900;
}

.form-errors
{
    display:none;
    color: red;
    font-size: 14px;
    padding: 10px 0 20px 0;
}

.slide-form-container .input-container input.input-error, .slide-form-container textarea.input-error
{
    border: 1px solid red;
}


@media screen and (max-width:1000px)
{
    .slide-form-container .form-fields-wrapper
    {
        flex-direction:column;
    }

    .slide-form-container .form-fields-wrapper textarea
    {
        min-height:100px;
    }
}

@media screen and (max-width:769px)
{
    .slide-form-container
    {
        top: 100px;
        width: 90%;
        height: calc(100% - 200px);
        min-height: 565px;
    }
}