/* Lato */
@font-face {
  font-family: 'Lato';
  src: url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
  font-weight:400;
  font-style:normal;
}

@font-face {
  font-family: 'Lato';
  src: url(./fonts/Lato/Lato-Bold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(./fonts/Lato/Lato-Light.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(./fonts/Lato/Lato-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor:default;
}

a
{
  color:inherit;
  text-decoration:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  padding:0 5%;
}

h1 {
  color:#fff;
  font-size:4em;
  margin:0;
  font-weight:800;
}

*
{
  box-sizing:border-box;
}

.closed
{
  display:none;
}